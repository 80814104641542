import React, { useCallback, useEffect, useMemo } from 'react'
import { Field } from 'react-final-form'
import { FormattedMessage } from 'react-intl'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { EColor } from '@frontend/pole-ui/lib/common/constants'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import { EIconName } from '@frontend/pole-ui/lib/components/Icon'
import classNames from 'classnames'
import { addDays } from 'date-fns'
import qs from 'qs'
import { useGetApi } from '@/api/hooks'
import { BankAccountsDropdown } from '@/components/composed/BankAccountMenuOption/BankAccountsDropdown'
import { createDocumentAssignment, getDealAgreementFormStore } from '@/components/composed/forms/DealAgreementForm/reduce'
import { openModal } from '@/components/composed/Modal/actions'
import PreviewDocument from '@/components/composed/PreviewDocument/PreviewDocument'
import { BubblingTooltip, Button, ErrorMessage, InjectHtml, Loader, TextWithIcon } from '@/components/ui'
import { Checkbox, DatePicker } from '@/components/ui/fields'
import SmsCodeTimer from '@/components/ui/SmsCodeTimer/SmsCodeTimer'
import { SERVER_DATE_FORMAT } from '@/constants/common'
import { useData } from '@/logic/data'
import { getLegalDocumentUrl } from '@/logic/legalDocuments/helpers'
import { EProfileRequestType } from '@/logic/profile/constants'
import { getRequestState } from '@/logic/requestStatus/reducer'
import { getOperationNextSendingTimestamp } from '@/logic/smsCode/reducer'
import type { RootState } from '@/redux/interfaces'
import type { TLegalDocuments } from '@/types/legalDocuments'
import { ELegalDocumentType } from '@/types/legalDocuments'
import { EAgrarianOrderStatus } from '@/types/Order'
import { downloadFileFromResponseBlob } from '@/utils/files'
import { getLinkOnDocument } from '@/utils/getLinkOnDocument'
import { useFormatDate } from '@/utils/hooks/format/useFormatDate'
import { useDeviceType } from '@/utils/hooks/useDeviceType'
import { useDownloadFile } from '@/utils/hooks/useDownloadFile'
import { datePickerDisableDateOffer } from '@/utils/offer/datePickerDisableDateOffer'
import { DAYS_TO_ACCEPT_REQUEST_OFFER, STYLE_FOR_OFFER } from '../constants'
import type { IDealAgreementContent, TDealAgreementOperationName, TDealAgreementStepProps } from '../interfaces'
import { EDealAgreementStatus } from '../interfaces'
import type { IEditingDisagreementProtocolModalOptions } from './EditingDisagreementProtocolModal/interfaces'

const mapState = (sendingCodeRequestName: string, operationName: TDealAgreementOperationName) => {
  return (state: RootState) => ({
    requestState: getRequestState(sendingCodeRequestName)(state),
    operationNextSendingTimestamp: getOperationNextSendingTimestamp(operationName)(state),
    dealAgreementFormState: getDealAgreementFormStore()(state),
  })
}

const AgreementFirstStep: TDealAgreementStepProps = props => {
  const {
    values,
    showNextForm,
    sendingCodeRequestName,
    contentUrl,
    urlToDownload,
    sendSmsCode,
    disagreementProtocol,
    saveDisagreementProtocol,
    goBack,
    accreditationId,
    disagreementButtonText,
    operationName,
    form,
    entityType,
    paymentType,
    orderId,
    orderStatusId,
    accounts,
  } = props

  const { validTo, isAgreed, organizationBankAccountId } = values

  const dispatch = useDispatch()
  const isMobile = useDeviceType()
  const { formatDate } = useFormatDate()

  const memoizedAccounts = useMemo(() => accounts, [accounts])

  const defaultAccount = useMemo(() => memoizedAccounts?.find(account => account.defaultAccount), [memoizedAccounts])

  useEffect(() => {
    if (!organizationBankAccountId && defaultAccount) {
      form.change('organizationBankAccountId', defaultAccount.id)
    }
  }, [organizationBankAccountId, defaultAccount?.id, defaultAccount, form])

  const version = useMemo(() => {
    const urlParams = new URLSearchParams(contentUrl.split('?')[1])
    const versionValue = urlParams.get('version') || undefined

    return versionValue
  }, [contentUrl])

  const buildUrlWithParams = useCallback(
    (baseUrl: string) => {
      const params: Record<string, string> = {}
      if (version) params.version = version
      if (organizationBankAccountId) params.organizationBankAccountId = organizationBankAccountId
      const queryString = qs.stringify(params, { addQueryPrefix: true })
      const fullUrl = `${baseUrl.split('?')[0]}${queryString}`

      return fullUrl
    },
    [version, organizationBankAccountId],
  )

  const contentUrlWithParams = useMemo(() => buildUrlWithParams(contentUrl), [buildUrlWithParams, contentUrl])
  const urlToDownloadWithParams = useMemo(() => buildUrlWithParams(urlToDownload), [buildUrlWithParams, urlToDownload])

  const {
    data,
    isLoading: isDealAgreementPayloadLoading,
    meta,
  } = useGetApi<IDealAgreementContent>(contentUrlWithParams, [contentUrlWithParams])

  const { data: documents = [] } = useData<TLegalDocuments>('legalDocuments')

  const {
    response,
    responseBlob,
    size,
    unit,
    isLoading: isDownloading,
    isFailure: isFailureFile,
  } = useDownloadFile<Blob>(urlToDownloadWithParams, [urlToDownloadWithParams])

  const { value = '', validToRequired } = data || {}

  const { requestState, operationNextSendingTimestamp, dealAgreementFormState } = useSelector(
    mapState(sendingCodeRequestName, operationName),
    shallowEqual,
  )

  const { isFailure, isLoaded: isSmsSent, isLoading: isSmsCodeSending } = requestState

  const isOrderType = entityType === EProfileRequestType.ORDERS
  const isAssignmentType = isOrderType && orderStatusId === EAgrarianOrderStatus.AGRARIAN_ASSIGNMENT_WAITING
  const isDatePickerNeeded = isOrderType

  useEffect(() => {
    if (isAssignmentType && orderId) {
      dispatch(createDocumentAssignment({ orderId }))
    }
  }, [dispatch, isAssignmentType, orderId])

  useEffect(() => {
    if (isSmsSent) {
      showNextForm()
    }
  }, [isSmsSent, showNextForm])

  const defaultValidToDate = useMemo(() => {
    return formatDate(addDays(new Date(), DAYS_TO_ACCEPT_REQUEST_OFFER), SERVER_DATE_FORMAT)
  }, [formatDate])

  useEffect(() => {
    if (validToRequired && !isDatePickerNeeded) {
      form.change('validTo', defaultValidToDate)
    }
  }, [validToRequired, isDatePickerNeeded, defaultValidToDate, form])

  const openEditingDisagreementProtocolModal = useCallback(() => {
    dispatch(
      openModal({
        options: { dialogId: 'EditingDisagreementProtocolModal' },
        contentOptions: {
          disagreementProtocol,
          goBack,
          saveDisagreementProtocol,
          accreditationId,
          version,
        } as IEditingDisagreementProtocolModalOptions,
      }),
    )
  }, [dispatch, disagreementProtocol, goBack, saveDisagreementProtocol, accreditationId, version])

  const htmlUrl = useMemo(() => {
    return URL.createObjectURL(new Blob([`${value}<style>${STYLE_FOR_OFFER}</style>`], { type: 'text/html' }))
  }, [value])

  const downloadDocument = () => {
    if (response && responseBlob) {
      const tab = window.open('', '_blank')
      downloadFileFromResponseBlob(response, responseBlob, 'Doc.pdf', tab)
    }
  }

  const shownOrganizationDetailsRequired = meta?.status === EDealAgreementStatus.ORGANIZATION_DETAILS_REQUIRED

  const sortedBankAccountOptions = useMemo(() => {
    if (!memoizedAccounts) return []

    return [...memoizedAccounts]
      .sort((a, b) => (b.defaultAccount ? 1 : 0) - (a.defaultAccount ? 1 : 0))
      .map(account => ({
        value: account.id,
        label: account.accountName,
        payload: {
          account: account.account,
          defaultAccount: account.defaultAccount,
        },
      }))
  }, [memoizedAccounts])

  return (
    <>
      <Loader isVisible={isDealAgreementPayloadLoading} />

      {shownOrganizationDetailsRequired ? (
        <div className="dealAgreementModalRequired">
          <InjectHtml html={meta?.message || ''} className="dealAgreementModalRequired__content" />
          <CommonLink url="/profile/organizationData" className="button button_green">
            <FormattedMessage id="dealAgreementModal.profile.button" />
          </CommonLink>
        </div>
      ) : (
        <>
          <h3 className="dealAgreementModal__title">
            <FormattedMessage id={`dealAgreementModal.${operationName}.title`} />
          </h3>

          <div className={classNames('dealAgreementModalWrapper', { withDatePicker: validToRequired && isDatePickerNeeded })}>
            {sortedBankAccountOptions.length > 0 && (
              <div className="dealAgreementModalDropdown">
                <Field
                  name="organizationBankAccountId"
                  render={({ input }) => (
                    <BankAccountsDropdown
                      {...input}
                      options={sortedBankAccountOptions}
                      className="dealAgreementModal__bankAccount"
                      isDisabled
                    />
                  )}
                />
              </div>
            )}
            <div className="dealAgreementModalContent">
              <PreviewDocument
                className="dealAgreementModalContent__document"
                documentData={isAssignmentType ? dealAgreementFormState.document : htmlUrl}
                documentContentType={isAssignmentType ? 'base64' : 'html'}
              />
            </div>
            <div className="dealAgreementModalItem">
              <div
                className={classNames('dealAgreementModalItem__info', {
                  dealAgreementModalItem__infoFail: isFailureFile,
                })}
              >
                <span>
                  <BubblingTooltip messageId={`dealAgreementModal.text.mobileDocument.${isFailureFile ? 'fail' : operationName}`}>
                    <span>
                      <FormattedMessage id={`dealAgreementModal.text.mobileDocument.${isFailureFile ? 'fail' : operationName}`} />
                    </span>
                  </BubblingTooltip>
                </span>
                {!isFailureFile && (
                  <>
                    <div />
                    <span className="dealAgreementModalItem__infoDescription">
                      PDF
                      {size && unit && (
                        <>
                          &nbsp;
                          {size}
                          &nbsp;
                          <FormattedMessage id={`dealAgreementModal.unit.${unit.toLowerCase()}`} />
                        </>
                      )}
                    </span>
                  </>
                )}
              </div>
              {isAssignmentType ? (
                <CommonLink
                  className="dealAgreementModal__downloadButton button button_outline button_full-width"
                  url={getLinkOnDocument(dealAgreementFormState.document)}
                  isDocument
                  target="_blank"
                >
                  <FormattedMessage id={`dealAgreementModal.${operationName}.downloadDocument`} />
                </CommonLink>
              ) : (
                <Button
                  className="dealAgreementModal__downloadButton"
                  modifiers={[isMobile ? 'green' : 'outline']}
                  onClick={downloadDocument}
                  loaderProps={{ isVisible: isDownloading }}
                  isDisabled={isFailureFile || !responseBlob}
                >
                  <FormattedMessage
                    id={isMobile ? 'dealAgreementModal.text.openDocument' : `dealAgreementModal.${operationName}.downloadDocument`}
                  />
                </Button>
              )}
            </div>
            {validToRequired && isDatePickerNeeded && (
              <DatePicker
                name="validTo"
                placeholderId="dealAgreementModal.validTo.placeholder"
                parsingFormat={SERVER_DATE_FORMAT}
                className="dealAgreementModal__datePicker"
                pickerProps={{ disabledDate: datePickerDisableDateOffer(paymentType) }}
              />
            )}
          </div>

          <Checkbox
            controlStyle={EColor.WHITE}
            borderColorStyle={EColor.GRAY}
            activeHoverBackgroundStyle={EColor.DARK_GREEN}
            hoverBackgroundStyle={EColor.LIGHT_GREEN}
            hoverControlStyle={EColor.BLACK}
            name="isAgreed"
            id="isAgreed"
            className="dealAgreementModal__agreed"
          >
            <div>
              <FormattedMessage
                id={`dealAgreementModal.${operationName}.agreementCheckbox.label`}
                values={{
                  registrationRulesLink: (
                    <CommonLink
                      shouldOpenInNewWindow
                      url={getLegalDocumentUrl(documents, ELegalDocumentType.registrationRules)}
                      className="underline underline_green color_green"
                    >
                      <FormattedMessage id="legalDocumentsPage.userRegistrationRules.text" />
                    </CommonLink>
                  ),
                }}
              />
            </div>
          </Checkbox>

          <ErrorMessage isVisible={isFailure} textId="dealAgreementModal.sendingSmsCode.error" className="text_center" />

          <SmsCodeTimer
            textId="dealAgreementModal.timeUntilDisplayingSendCodeButton.text"
            className="text_center"
            operationName={operationName}
          />

          <div className="dealAgreementModal__bottomLine row">
            <div className="col-12">
              <Button
                onClick={() => {
                  sendSmsCode(values)
                }}
                modifiers={['green', 'full-width']}
                textId={`dealAgreementModal.${operationName}.sendingCodeButton`}
                className="dealAgreementModal__button dealAgreementModal__buttonAccepting"
                isDisabled={!isAgreed || (validToRequired && !validTo) || Boolean(operationNextSendingTimestamp)}
                loaderProps={{ isVisible: isSmsCodeSending }}
              />
            </div>
            {disagreementButtonText && (
              <div className="dealAgreementModal__disagreement col-12">
                <Button
                  onClick={openEditingDisagreementProtocolModal}
                  modifiers={['outline', 'full-width']}
                  className="deal-agreement-modal__button"
                  loaderProps={{ isVisible: isSmsCodeSending }}
                >
                  {disagreementButtonText}
                </Button>
              </div>
            )}
          </div>

          <TextWithIcon
            iconProps={{ name: EIconName.InfoFill, style: { color: EColor.LIGHT_GRAY } }}
            className="dealAgreementModal__notice"
            textId={`dealAgreementModal.${operationName}.notice`}
          />
        </>
      )}
    </>
  )
}

export default AgreementFirstStep

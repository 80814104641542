import type { Dictionary, PayloadAction } from '@reduxjs/toolkit'
import { createReducer } from '@reduxjs/toolkit'
import Cookies from 'js-cookie'
import { getInitialState } from '@/redux/helpers'
import type { RootState } from '@/redux/interfaces'
import { reducerManager } from '@/redux/reducerManager'
import { allowSendingCode, disableSendingCode, saveTokenId } from './actions'
import { disabledSendingCodeTime } from './constants'
import type { ISmsCodeState } from './interfaces'

const cookieName = 'savedOperationsNextSendingTimestamps'
const cookie = Cookies.get(cookieName)
const savedOperationsNextSendingTimestamps = cookie ? JSON.parse(cookie) : null

const initialState: ISmsCodeState = {
  operationsNextSendingTimestamps: savedOperationsNextSendingTimestamps || {},
}

function saveOperationsNextSendingTimestamps(state: Dictionary<number>) {
  Cookies.set(cookieName, JSON.stringify(state))
}

const reducerName = 'smsCode'

const reducer = createReducer(getInitialState(reducerName, initialState), {
  [saveTokenId.type]: (state, { payload }: PayloadAction<string>) => {
    state.tokenId = payload
  },
  [disableSendingCode.type]: (state, { payload }: PayloadAction<string | undefined>) => {
    const operation = payload || 'common'
    state.operationsNextSendingTimestamps[operation] = Date.now() + disabledSendingCodeTime
    saveOperationsNextSendingTimestamps(state.operationsNextSendingTimestamps)
  },
  [allowSendingCode.type]: (state, { payload }: PayloadAction<string | undefined>) => {
    const operation = payload || 'common'
    state.operationsNextSendingTimestamps[operation] = undefined
    saveOperationsNextSendingTimestamps(state.operationsNextSendingTimestamps)
  },
})

reducerManager.add(reducerName, reducer)

export const getTokenId = () => (state: RootState) => state[reducerName].tokenId

export const getOperationNextSendingTimestamp =
  (operationName: string = 'common') =>
  (state: RootState) =>
    state[reducerName].operationsNextSendingTimestamps[operationName]

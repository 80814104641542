import type { ReactElement } from 'react'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { setInitialStatus } from '@/logic/requestStatus/actions'
import { getRequestMeta, getRequestState } from '@/logic/requestStatus/reducer'
import type { RootState } from '@/redux/interfaces'
import { makeAllFieldsTouched } from '@/utils/fields'
import { useMount } from '@/utils/hooks'
import CustomizedLeasingFormFields from './components/CustomizedLeasingFormFields'
import type { ICustomizedLeasingFormProps, ICustomizedLeasingWizardForm } from './interfaces'

const mapState = (requestStatusName: string) => (state: RootState) => ({
  requestState: getRequestState(requestStatusName)(state),
  requestStatusMeta: getRequestMeta(requestStatusName)(state),
})

function CustomizedLeasingForm(props: ICustomizedLeasingFormProps): ReactElement {
  const { onSubmit, initialValues, requestStatusName, tooltipText, shouldDisableButton } = props
  const { requestState, requestStatusMeta } = useSelector(mapState(requestStatusName))
  const dispatch = useDispatch()
  const requestMessage = typeof requestStatusMeta === 'object' ? '' : requestStatusMeta

  const form = useForm<ICustomizedLeasingWizardForm>({
    mode: 'onTouched',
    defaultValues: initialValues,
  })

  const clearRequestState = () => {
    if (requestStatusName) dispatch(setInitialStatus({ name: requestStatusName }))
  }

  const handleSubmitAndTouchAllFields: Parameters<typeof form.handleSubmit>[0] = values => {
    onSubmit(values)
    makeAllFieldsTouched(form)
  }

  const handleSubmitErrorAndTouchAllFields: Parameters<typeof form.handleSubmit>[1] = () => {
    makeAllFieldsTouched(form)
  }

  useMount(clearRequestState, clearRequestState)

  return (
    <form className="wizard-form" onSubmit={form.handleSubmit(handleSubmitAndTouchAllFields, handleSubmitErrorAndTouchAllFields)}>
      <CustomizedLeasingFormFields
        requestState={requestState}
        requestStatusMeta={requestMessage}
        tooltipText={tooltipText}
        shouldDisableButton={shouldDisableButton}
        form={form}
        regionSelectErrorMessage={props.regionSelectErrorMessage}
      />
    </form>
  )
}

export default CustomizedLeasingForm

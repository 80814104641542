import { isBefore } from 'date-fns'
import { isBeforeBusinessDays } from '@/components/ui/fields/DatePicker/helpers'
import { EOrderPaymentVariantType } from '@/types/Cart'
import { MIN_DAYS_TO_ACCEPT_OFFER_WITH_CREDIT } from './constants'

export const datePickerDisableDateOffer = (paymentType?: EOrderPaymentVariantType) => {
  switch (paymentType) {
    case EOrderPaymentVariantType.MICROCREDIT:
      return (date?: Date) => isBeforeBusinessDays(date, MIN_DAYS_TO_ACCEPT_OFFER_WITH_CREDIT)
    default:
      return (date: Date | undefined = new Date()) => isBefore(date, new Date())
  }
}

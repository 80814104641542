import React, { useEffect, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import type ReactCodeInput from 'react-verification-code-input'
import { SmsCode } from '@/components/ui/fields'
import { getRequestMeta, getRequestState } from '@/logic/requestStatus/reducer'
import { allowSendingCode } from '@/logic/smsCode/actions'
import { getOperationNextSendingTimestamp } from '@/logic/smsCode/reducer'
import type { RootState } from '@/redux/interfaces'
import type { TDealAgreementOperationName, TDealAgreementStepProps } from '../interfaces'

const mapState =
  (sendingCodeRequestName: string, signingDealRequestName: string, operationName: TDealAgreementOperationName) => (state: RootState) => ({
    agreementRequestState: getRequestState(signingDealRequestName)(state),
    agreementRequestMeta: getRequestMeta(signingDealRequestName)(state),
    sendingSmsRequestState: getRequestState(sendingCodeRequestName)(state),
    operationNextSendingTimestamp: getOperationNextSendingTimestamp(operationName)(state),
  })

const AgreementSecondStep: TDealAgreementStepProps = props => {
  const { form, sendingCodeRequestName, signingDealRequestName, sendSmsCode, values, operationName } = props

  const { agreementRequestState, sendingSmsRequestState, operationNextSendingTimestamp, agreementRequestMeta } = useSelector(
    mapState(sendingCodeRequestName, signingDealRequestName, operationName),
  )
  const { isLoading: isSendingCodeInProcess, isFailure: isSendingCodeFailure } = sendingSmsRequestState
  const { isLoading: isAcceptingAgreementInProcess, isFailure: isAcceptingAgreementFailure } = agreementRequestState
  const { status } = agreementRequestMeta || {}
  const dispatch = useDispatch()
  const smsCodeRef = useRef<ReactCodeInput>(null)

  useEffect(() => {
    if (isAcceptingAgreementFailure) {
      form.change('code', '')
      //eslint-disable-next-line
      smsCodeRef.current?.__clearvalues__()
    }

    //eslint-disable-next-line
  }, [isAcceptingAgreementFailure])

  const canSendCodeAgain = !operationNextSendingTimestamp

  return (
    <div className="row row_column row-align_center">
      <h3 className="dealAgreementModal__title dealAgreementModal__title_enter-code">
        <FormattedMessage id="dealAgreementModal.enterCode.title" />
      </h3>
      <SmsCode
        defaultCode={values.code}
        name="code"
        shouldShowTimer={!canSendCodeAgain}
        shouldShowSendingCodeButton={canSendCodeAgain}
        shouldShowError={isSendingCodeFailure || isAcceptingAgreementFailure}
        timerTextId="dealAgreementModal.timeUntilDisplayingSendCodeButton.text"
        sendingSmsButtonClassName="dealAgreementModal__button_accepting"
        sendingSmsButtonTextId="dealAgreementModal.sendCodeAgainButton.text"
        errorMessageId={
          isSendingCodeFailure ? 'dealAgreementModal.sendingSmsCode.error' : `dealAgreementModal.errorText.${status || 'ERROR'}`
        }
        submitButtonTextId={`dealAgreementModal.submit.${operationName}.text`}
        smsCodeRef={smsCodeRef}
        isLoading={isAcceptingAgreementInProcess}
        isSendingCodeInProcess={isSendingCodeInProcess}
        timeUntilSendingCode={operationNextSendingTimestamp}
        onTimerCompleted={() => dispatch(allowSendingCode(operationName))}
        sendSmsCode={() => sendSmsCode(values)}
      />
    </div>
  )
}

export default AgreementSecondStep

import type { TMessages } from '@/types/TMessages'

export const messages = {
  submitButton: 'Отправить заявку',
  label: {
    email: 'E-mail',
    comment: 'Желаемая марка и модель',
  },
  leasing: {
    contacts: {
      title: 'Ваша контактная информация',
    },
    agreement: {
      text: 'Ввод номера телефона подтверждает ваше согласие с <LinkToAgreement>политикой обработки персональных данных</LinkToAgreement>',
    },
  },
  error: {
    needToAgree: 'Ваше согласие нужно, чтобы отправить заявку',
    requiredPhone: 'Введите свой номер телефона',
    requiredEmail: 'Введите свой email',
    requiredEquipmentDetails: 'Укажите модель и стоимость техники',
    requiredCity: 'Выберите ближайший населенный пункт к месту ведения бизнеса',
  },
} satisfies TMessages

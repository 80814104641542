import React from 'react'
import type { IDropdownOption } from '@frontend/pole-ui/lib/components/Dropdown'
import { Dropdown } from '@frontend/pole-ui/lib/components/Dropdown'
import { messages } from './messages'
import './BankAccountsDropdown.scss'

interface BankAccountsDropdownProps {
  name: string
  className?: string
  options: IDropdownOption<string>[]
  defaultValue?: string
  onChange: (value: string) => void
  value: string
  isDisabled?: boolean
}

export const BankAccountsDropdown: React.FC<BankAccountsDropdownProps> = ({
  name,
  className,
  options,
  defaultValue,
  value,
  onChange,
  isDisabled,
}) => {
  return (
    <Dropdown
      name={name}
      value={value}
      onChange={onChange}
      className={`bankAccountsDropdown ${className || ''}`}
      options={options}
      defaultValue={defaultValue}
      isDisabled={isDisabled}
      SelectedValue={option =>
        !Array.isArray(option.item) ? (
          <div className="bankAccountsDropdown__menu">
            <div className="bankAccountsDropdown__menu-header">
              <span className="bankAccountsDropdown__menu-header--label">{option.item?.label}</span>
              <span className="bankAccountsDropdown__option--account"> {option.item?.payload?.account}</span>
              {option.item?.payload?.defaultAccount && (
                <span className="bankAccountsDropdown__option--default"> {messages.defaultAccount}</span>
              )}
            </div>
          </div>
        ) : null
      }
      MenuItem={option => (
        <div className="bankAccountsDropdown__option">
          <span className="bankAccountsDropdown__option--label">{option.item?.label}</span>
          <span className="bankAccountsDropdown__option--account"> {option.item?.payload?.account}</span>
          {option.item?.payload?.defaultAccount && (
            <span className="bankAccountsDropdown__option--default"> {messages.defaultAccount}</span>
          )}
        </div>
      )}
    />
  )
}

import type React from 'react'
import type { RequestBankAccount } from '@/api/kubik/monolith'
import type { EProfileRequestType } from '@/logic/profile/constants'
import type { EOrderPaymentVariantType } from '@/types/Cart'
import type { EAgrarianOrderStatus } from '@/types/Order'
import type { TEntityId } from '@/types/TEntityId'
import type { IWizardPageComponentProps } from '../WizardForm/interfaces'
import type { IEditingDisagreementProtocolModalFormValues } from './components/EditingDisagreementProtocolModal/interfaces'

export interface IDealAgreementFormValues {
  code: string
  validTo: string
  isAgreed: boolean
  organizationBankAccountId?: string
}

export type TAccreditationDealAgreementOperationName = `accreditation.${'agrarian' | 'contragent' | 'logisticsContractOffer'}`
export type TSaleAndOrderDealAgreementOperationName = 'bill' | 'assignment' | `offer.${'agrarian' | 'contragent'}`
export type TDealAgreementOperationName = TAccreditationDealAgreementOperationName | TSaleAndOrderDealAgreementOperationName

export interface IDealAgreementStepProps {
  disagreementButtonText?: string
  sendingCodeRequestName: string
  signingDealRequestName: string
  sendSmsCode: (values: IDealAgreementFormValues) => void
  contentUrl: string
  urlToDownload: string
  disagreementProtocol?: string
  saveDisagreementProtocol?: (values: IEditingDisagreementProtocolModalFormValues) => void
  goBack?: () => void
  accreditationId?: TEntityId
  version?: string
  operationName: TDealAgreementOperationName
  entityType?: EProfileRequestType
  paymentType?: EOrderPaymentVariantType
  orderStatusId?: EAgrarianOrderStatus
  orderId?: number
  accounts?: RequestBankAccount[]
}

export type TDealAgreementStepProps = React.FC<IDealAgreementStepProps & IWizardPageComponentProps<IDealAgreementFormValues>>

export interface IDealAgreementFormProps {
  stepProps: IDealAgreementStepProps
  onSubmit: (values: IDealAgreementFormValues) => void
}

export interface IDealAgreementContent {
  value: string
  validToRequired?: boolean
}

export interface IDealAgreementFileDownload {
  url: string
  tab?: Window | null
}

export enum EDealAgreementStatus {
  ORGANIZATION_DETAILS_REQUIRED = 'ORGANIZATION_DETAILS_REQUIRED',
}

export interface IDealAgreementFormStore {
  document: Maybe<string>
}

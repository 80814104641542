export const STYLE_FOR_OFFER = `
.hidden-on-agromarket {
  display: none !important;
}

.visible-on-agromarket {
  display: block !important;
}
`
export const DAYS_TO_ACCEPT_REQUEST_OFFER = 1

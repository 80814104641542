import loadable from '@loadable/component'

export { default as AgreementFirstStep } from './AgreementFirstStep'
export { default as AgreementSecondStep } from './AgreementSecondStep'

export const EditingDisagreementProtocolModal = loadable(
  /* webpackChunkName: "EditingDisagreementProtocolModal" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./EditingDisagreementProtocolModal'),
)

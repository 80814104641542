import type { FC } from 'react'
import React, { memo } from 'react'
import type { IPreviewDocumentProps } from './types'

const PreviewDocument: FC<IPreviewDocumentProps> = props => {
  const { documentData, documentContentType, className } = props

  if (!documentData) return null

  if (documentContentType === 'base64')
    return (
      <iframe
        className={className}
        src={`data:application/pdf;base64, ${documentData}#toolbar=0&navpanes=0&scrollbar=0`}
        width="100%"
        height="353px"
      />
    )

  return <iframe className={className} src={documentData} />
}

export default memo(PreviewDocument)

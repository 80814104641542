import { useEffect, useMemo, useState } from 'react'
// eslint-disable-next-line camelcase
import { unstable_batchedUpdates } from 'react-dom'
import type { IAjaxResponse, TUnit } from '@/api/interfaces'
import { requestDataWithRawResponse } from '@/api/request'
import { formatBytes } from '../files'

export interface IUseDownloadFile<T> {
  response?: IAjaxResponse<T>
  responseBlob: Blob | null
  isLoading: boolean
  isFailure: boolean
  isLoaded: boolean
  size: number | null
  unit: TUnit | null
}
//eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useDownloadFile = <T>(url: string, deps: any[] = []): IUseDownloadFile<T> => {
  const [response, setResponse] = useState<IAjaxResponse<T>>()
  const [responseBlob, setResponseBlob] = useState<Blob | null>(null)
  const [isLoading, setLoadingState] = useState<boolean>(true)
  const [isLoaded, setLoadedState] = useState<boolean>(false)
  const [isFailure, setFailureState] = useState<boolean>(false)

  const [size, unit] = useMemo(() => {
    if (!response) return [null, null]

    return formatBytes(Number(response.headers.get('content-length')) || 0)
  }, [response])

  useEffect(() => {
    requestDataWithRawResponse<T>({ url, method: 'GET' })
      .then(resp => {
        resp.blob().then(result => {
          unstable_batchedUpdates(() => {
            setResponseBlob(result)

            setResponse(resp)
            setLoadedState(true)
          })
        })
      })
      .catch(() => {
        setFailureState(true)
      })
      .finally(() => {
        setLoadingState(false)
      })
    //eslint-disable-next-line
  }, [...deps])

  return {
    response,
    responseBlob,
    isLoading,
    isLoaded,
    isFailure,
    size,
    unit,
  }
}

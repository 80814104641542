import Loadable from '@loadable/component'

export { default as WizardForm } from './WizardForm'
export { default as CustomizedForm } from './CustomizedForms/CustomizedForm'
export { default as CustomizedLeasingForm } from './CustomizedForms/CustomizedLeasingForm'
export { default as DealAgreementForm } from './DealAgreementForm'

export const SorterForm = Loadable(
  /* webpackChunkName: "SorterForm" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./SorterForm'),
)

export const UploadDocForm = Loadable(
  /* webpackChunkName: "UploadDocForm" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./UploadDocForm'),
)
export const UploadDocumentExampleForm = Loadable(
  /* webpackChunkName: "UploadDocForm" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./UploadDocumentExampleForm'),
)

export const SearchForm = Loadable(
  /* webpackChunkName: "SearchForm" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./SearchForm'),
)

export const UploadFileFormWithProgressBar = Loadable(
  /* webpackChunkName: "UploadFileFormWithProgressBar" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./UploadFileFormWithProgressBar'),
)

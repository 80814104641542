import type { FC } from 'react'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { ECustomLeasingRadioOrder } from '@/components/composed/forms/CustomizedForms/CustomizedLeasingForm/enums'
import { Image } from '@/components/ui'
import { IS_ALFA_AND_STONE_XXI_LEASING_PRODUCTS_HIDDEN, LEASING_COMPANY_LOGOS } from '@/constants/financing'
import { useWebpExtension } from '@/utils/hooks/image'
import { messages } from './messages'
import './styles.scss'

const CustomLeasingRadioLabel: FC<{ title?: string; order: ECustomLeasingRadioOrder; iconUrl?: string }> = ({ title, order, iconUrl }) => {
  const { formatMessage } = useIntl()
  const imageExtension = useWebpExtension()
  const logos = useMemo(() => [...LEASING_COMPANY_LOGOS].reverse(), [])

  switch (order) {
    case ECustomLeasingRadioOrder.FIRST: {
      return (
        <div>
          <div className="custom-leasing-radio custom-leasing-radio__single">
            <h5>{formatMessage({ id: title })}</h5>
            <div className="custom-leasing-radio__icon">
              <Image src={`${iconUrl}.${imageExtension}`} isInStatic />
            </div>
          </div>
        </div>
      )
    }
    case ECustomLeasingRadioOrder.SECOND: {
      const leasingCompaniesEnumeration = IS_ALFA_AND_STONE_XXI_LEASING_PRODUCTS_HIDDEN
        ? messages.lessorsWithNoAlfaAndStoneXXI
        : messages.lessors

      return (
        <div>
          <div className="custom-leasing-radio custom-leasing-radio__multiple">
            <h5 className="custom-leasing-radio__title">{messages.title}</h5>
            <div className="custom-leasing-radio__subtitle text_small">{messages.subtitle}</div>
            <div className="custom-leasing-radio__list space-holder-top8 color_pale-black text_small">{leasingCompaniesEnumeration}</div>
            <ul className="custom-leasing-radio__logos">
              {logos.map(logo => (
                <li className="custom-leasing-radio__icon" key={logo}>
                  <Image src={`index/partners/${logo}-leasing-short.${imageExtension}`} isInStatic />
                </li>
              ))}
            </ul>
          </div>
        </div>
      )
    }
    default:
      return null
  }
}
export default React.memo(CustomLeasingRadioLabel)
